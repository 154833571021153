import React from "react"
import { GatsbyImage, getSrc } from "gatsby-plugin-image"
import Moment from "react-moment"
import { motion } from "framer-motion"
import { Link, injectIntl } from "gatsby-plugin-intl-v3";
import { fadeBumpDelayLong, fadeBumpDelay, fadeBump } from "../helpers/transitionHelper"
import { graphql } from "gatsby";
import SEO from "../components/seo"

const JournalPage = ({ intl, data }) => {
  return <>
    <SEO
      title={intl.formatMessage({ id: "discog" })}
      image={getSrc(data.contentfulMainPage.horizontal[3])}
    />
    <motion.section
      initial="initial"
      animate="enter"
      exit="exit"
      className="pt-12 md:pt-40 pb-24 md:pb-40 overflow-hidden relative"
      data-scroll-section
    >
      <motion.div variants={fadeBumpDelay} className="hidden md:block fixed top-0 left-0 z-0 w-2/12 xl:pr-8 mt-35vh -ml-8" data-scroll data-scroll-speed="2.25">
        <GatsbyImage
          image={data.contentfulMainPage.horizontal[3].gatsbyImageData}
          className="w-full opacity-0 md:opacity-50"
          alt={data.contentfulMainPage.horizontal[3].title} />
      </motion.div>

      <motion.div variants={fadeBumpDelayLong} className="hidden md:block fixed top-0 right-0 z-0 w-2/12 xl:pl-6 mt-65vh lg:mt-45vh -mr-8" data-scroll data-scroll-speed="2.25">
        <GatsbyImage
          image={data.contentfulMainPage.horizontal[1].gatsbyImageData}
          className="w-full opacity-0 md:opacity-50"
          objectPosition="right"
          alt={data.contentfulMainPage.horizontal[1].title} />
      </motion.div>

      <motion.div
        initial="initial"
        animate="enter"
        exit="exit"
        variants={{
          enter: { transition: { staggerChildren: 0.025 } }
        }}
      >
        <div className="w-full md:w-1/2 md:pl-16 lg:w-7/12 mx-auto relative">
          {data.allContentfulAlbum.edges.map(({ node }, index) => {
            return (
              <motion.div
                key={index}
                variants={fadeBump}
                className="relative mb-12 md:mb-16 mx-12 md:mx-0"
              >
                <Link to={`/discography/${node.slug}`} className="flex flex-wrap lg:-mx-3 items-center group">
                  <div className="w-full lg:w-1/3 lg:px-3 mb-4 lg:mb-0">
                    <div className="relative overflow-hidden">
                      <GatsbyImage
                        image={node.coverImage.gatsbyImageData}
                        className="w-full transition ease-in-out duration-700 opacity-70 focus:opacity-100 hover:opacity-100"
                        alt={node.coverImage.title} />
                    </div>
                  </div>
                  <div className="w-full lg:w-2/3 lg:px-3">
                    <div className="px-4 md:px-0">
                      <span className="block uppercase text-xs font-sans tracking-widest mb-3 pl-1"><Moment format="YYYY">{node.releaseDate}</Moment> {"|"} {node.label}</span>
                      <span className="text-8xlvw md:text-3xlvw lg:text-xlvw font-display relative leading-none  tracking-tighter pb-0 mb-0">{ node.name }</span>
                    </div>
                  </div>
                </Link>
              </motion.div>
            );
          })}
        </div>
      </motion.div>
    </motion.section>
  </>;
}

export default injectIntl(JournalPage)

export const query = graphql`
    query ContentFulDisc($language: String) {
        allContentfulAlbum(sort: {fields: releaseDate, order: DESC}, filter: {node_locale: {eq: $language}}) {
            edges {
                node {
                    name
                    slug
                    label
                    coverImage {
                        gatsbyImageData (layout: CONSTRAINED, width: 500, placeholder: BLURRED)
                        title
                    }
                    releaseDate(formatString: "YYYY")
                }
            }
        }
        contentfulMainPage(
            contentful_id: { eq: "3IemKmfKhhIl4iKQaLPfme" }
            node_locale: { eq: $language }
        ) {
            horizontal {
                title
                gatsbyImageData(layout: CONSTRAINED, width: 600, height: 1000)
            }
        }
    }
`;